import React from "react";

import {NavLink} from "react-router-dom"

function Nav() {

    return (
        <>
            <div className="Nav">
                <nav>
                    <ul className="flex">
                        <li><NavLink to="/">Bio</NavLink></li>
                        <li><NavLink to="projects">Personal Projects</NavLink></li>
                        <li><NavLink to="about">About This Website</NavLink></li>
                        <li><NavLink to="info">Info</NavLink></li>
                    </ul>
                </nav>
            </div>
        </>

    );
}

export default Nav;
