import React from "react";
import {ReactComponent as TwitterLogo} from '../twitter-brands.svg';

function Footer() {
    let year = new Date().getFullYear().toString().slice(-2);
    return (
        <div className="border-t border-black border-solid mt-10" style={{maxWidth: 800}}>
            <div className="w-full block mx-auto text-center pt-3">
                &copy;2016-{year} <a href="//nlud.uk/">nlud.uk</a> by <a href="//shortdark.co.uk/"
                                                                         rel="noreferrer noopener"
                                                                         target="_blank">Shortdark</a>.
                Made with <a href="//reactjs.org/" rel="noreferrer noopener" target="_blank">ReactJS</a> and <a
                href="//tailwindcss.com/" rel="noreferrer noopener" target="_blank">Tailwind CSS</a>.
            </div>
        </div>

    );
}

export default Footer;
